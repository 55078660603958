import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

function StatusRow({status, name, position}) {
    const statusBadge = function (status) {
        if (status === "OK") {
            return <div className="blob green" style={{marginLeft: "20px", marginTop: "22px"}}/>
        } else if (status === "ERROR") {
            return <div className="blob red" style={{marginLeft: "20px", marginTop: "22px"}}/>
        } else if (status === "WARN") {
            return <div className="blob yellow" style={{marginLeft: "20px", marginTop: "22px"}}/>
        } else if (status === "UNKNOWN") {
            return <div className="blob gray" style={{marginLeft: "20px", marginTop: "22px"}}/>
        } else {
            return <div className="blob gray" style={{marginLeft: "20px", marginTop: "22px"}}/>
        }
    }

    const statusText = function (status) {
        if (status === "OK") {
            return <Box color="#419E7B" style={{ fontSize: '15px', padding: 0, paddingTop:"15px", paddingRight:"14px", margin: 0, textAlign:'right'}}>
                <b>verfügbar</b>
            </Box>
        } else if (status === "ERROR") {
            return <Box color="#FF5252" style={{ fontSize: '15px', padding: 0, paddingTop:"15px", paddingRight:"14px", margin: 0, textAlign:'right'}}>
                <b>nicht verfügbar</b>
            </Box>
        } else if (status === "WARN") {
            return <Box color="#FFB142" style={{ fontSize: '15px', padding: 0, paddingTop:"15px", paddingRight:"14px", margin: 0, textAlign:'right'}}>
                <b>eingeschränkt</b>
            </Box>
        } else if (status === "UNKNOWN") {
            return <Box color="#6A6A6A" style={{ fontSize: '15px', padding: 0, paddingTop:"15px", paddingRight:"14px", margin: 0, textAlign:'right'}}>
                <b>unbekannt</b>
            </Box>
        } else {
            return <Box color="#6A6A6A" style={{ fontSize: '15px', padding: 0, paddingTop:"15px", paddingRight:"14px", margin: 0, textAlign:'right'}}>
                <b>unbekannt</b>
            </Box>
        }
    }

    const positionStyle = function (position) {
        if (position === "TOP") {
            return {height: "55px", borderRadius:'4px', borderBottomLeftRadius:0, borderBottomRightRadius:0, borderBottomStyle: 'none'}
        } else if (position === "MID") {
            return {height: "55px", borderRadius:0, borderBottomStyle: 'none'}
        } else if (position === "BOT") {
            return {height: "55px", borderRadius:'4px', borderTopLeftRadius:0, borderTopRightRadius:0}
        } else {
            return {height: "55px", borderRadius:'4px'}
        }
    }

  return (
    <Paper variant="outlined" sx={positionStyle(position)}>
        <Grid container spacing={0}>
        <Grid item xs={2} md={1}>
            { statusBadge(status) }
        </Grid>
        <Grid item xs={5} md={6}>
            <Box style={{ padding: 0, paddingTop:"15px", margin: 0, marginLeft:'-10px'}}>
                {name}
            </Box>
        </Grid>
        <Grid item xs={5} md={5}>
            { statusText(status) }
        </Grid>
        </Grid>
    </Paper>
  );
}

export default StatusRow;
