import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Grid from '@mui/material/Grid';

function GlobalStatus({status}) {

    const statusIcon = function (status) {
        if (status === "OK") {
            return <CheckCircleOutlineIcon sx={{color:'#fff', fontSize:'32px', marginTop:'7px'}}/>
        } else if (status === "ERROR") {
            return <ErrorOutlineIcon sx={{color:'#fff', fontSize:'32px', marginTop:'7px'}}/>
        } else if (status === "WARN") {
            return <WarningAmberIcon sx={{color:'#fff', fontSize:'32px', marginTop:'7px'}}/>
        } else {
            return <CheckCircleOutlineIcon sx={{color:'#fff', fontSize:'32px', marginTop:'7px'}}/>
        }
    }

    const statusColor = function (status) {
        if (status === "OK") {
            return "#419E7B"
        } else if (status === "ERROR") {
            return "#F41d1d"
        } else if (status === "WARN") {
            return "#F47a1d"
        } else {
            return "#419E7B"
        }
    }

    const statusText = function (status) {
        if (status === "OK") {
            return "Alle Systeme verfügbar"
        } else if (status === "ERROR") {
            return "Keine Systeme verfügbar"
        } else if (status === "WARN") {
            return "Nicht alle Systeme verfügbar"
        } else {
            return "Alle Systeme verfügbar"
        }
    }

    return (
        <Paper variant="outlined" sx={{borderRadius:'4px'}}>
            <Grid container spacing={0}>
                <Grid item xs={2} md={1}>
                <Box bgcolor={ statusColor(status) } sx={{borderTopLeftRadius:'4px', borderBottomLeftRadius:'4px'}} align="center">
                    { statusIcon(status) }
                </Box>
                </Grid>
                <Grid item xs={10} md={11}>
                <Box>
                    <Container style={{ padding: 0, paddingLeft: "13px", paddingTop:"12px", margin: 0}}>
                    <b>{ statusText(status) }</b>
                    </Container>
                </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default GlobalStatus;
