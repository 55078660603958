import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Divider, LinearProgress } from '@mui/material';
import StatusRow from './StatusRow';
import GlobalStatus from './GlobalStatus';
import axios from 'axios'

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#5591b5',
        main: '#2B76A3',
        dark: '#1e5272',
        contrastText: '#fff',
      },
      secondary: {
        light: '#834bff',
        main: '#651fff',
        dark: '#4615b2',
        contrastText: '#fff',
      },
      background: {
        default: "#F3F3F4"
      },
    }
  });

  const getMappedPosition = function (currentIndex, length) {
    if (length === 1) {
      return ""
    }

    if (currentIndex === 0) {
      return "TOP";
    } else if (currentIndex === (length-1)) {
      return "BOT";
    } else {
      return "MID";
    }
  }
  const getMappedStatus = function (status) {
    if (status === 8) {
      return "WARN"
    } else if (status === 9) {
      return "ERROR"
    } else if (status === 0 || status === 1) {
      return "UNKNOWN"
    } else {
      return "OK"
    }
  }

  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState({})
  const [systemStatus, setSystemStatus] = useState("")
  const website = document.location.hostname
  let url = "";

  useEffect(() => {
    if (loading) {
      if (website === "localhost") {
        url = "http://localhost:8083/status/"
      } else {
        url = "https://status-api.it-scharfenberger.de/status/"
      }
      axios.get(url + website).then((res) => {
        setStatus(res.data)
        let errorMonitors = 0
        let warnMonitors = 0
        let okMonitors = 0
        res.data.monitors.map((monitor) => {
          if (monitor.status === 9) {
            errorMonitors++
          } else if (monitor.status === 8) {
            warnMonitors++
          } else {
            okMonitors++
          }
        })
        if (errorMonitors > 0) {
          if (errorMonitors === res.data.monitors.length) {
            // all systems down
            setSystemStatus("ERROR")
          } else {
            // only few systems down
            setSystemStatus("WARN")
          }
        } else {
          if (warnMonitors > 0) {
            // some systems have waring
            setSystemStatus("WARN")
          } else {
            setSystemStatus("OK")
          }
        }
        setLoading(false)
      })
    }
  }, [loading])
  

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            <Container maxWidth="md">
              <Typography
                  variant="h6"
                  component="div">
                {status?.name ?? website} - Status
              </Typography>
            </Container>

          </Toolbar>
        </AppBar>
        <main>
          {/* Hero unit */}
          <Box sx={{paddingTop: '100px'}}>
            <Container maxWidth="md">
              { loading
                ? <LinearProgress />
                : <>
                  <GlobalStatus status={ systemStatus } />
                  <br/>
                  <Divider />
                  <br/>
                  {
                    status.monitors.map((monitor, index) => {
                      let pos = getMappedPosition(index, status.monitors.length);

                      return <StatusRow 
                        key={index}
                        name={monitor.name} 
                        status={getMappedStatus(monitor.status)} 
                        position={pos} />
                    })
                  }
                </>
              }
              <br />
              <br />
              <Typography fontSize="15px" align='center'>
                Status bereitgestellt von <a href="https://it-scharfenberger.de" target="_blank" style={{color: "#2A76A3"}}>IT Scharfenberger</a>
              </Typography>
            </Container>
          </Box>
        </main>
      </ThemeProvider>
  );
}

export default App;
